import {useIntl} from 'react-intl'
import {useAuth} from '../../../../app/modules/auth'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  const intl = useIntl()

  const {currentModules, currentFeaturesObject} = useAuth()

  return (
    <>
      {currentModules?.includes('asset') ? (
        <MenuInnerWithSub
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.ASSET.DASHBOARD'})}
          to='/asset/dashboard'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/asset/dashboard'
              hasBullet={true}
            />
            {currentFeaturesObject && currentFeaturesObject.manufacturer ? (
              <MenuItem
                title={intl.formatMessage({id: 'MENU.ASSET.MANUFACTURER'})}
                to='/asset/manufacturer'
                hasBullet={true}
              />
            ) : (
              <MenuItem
                isDisabled={true}
                title={intl.formatMessage({id: 'MENU.ASSET.MANUFACTURER'})}
                to='/not-paid/asset'
                hasBullet={true}
              />
            )}
            <MenuItem
              title={intl.formatMessage({id: 'MANUFACTURER.PRODUCT_PECIFICATIONS.TITLE'})}
              to='/asset/product-specifications/list'
              hasBullet={true}
            />
            {currentFeaturesObject && currentFeaturesObject.equipment ? (
              <MenuItem
                title={intl.formatMessage({id: 'MENU.ASSET.EQUIPMENT'})}
                to='/asset/equipment'
                hasBullet={true}
              />
            ) : (
              <MenuItem
                isDisabled={true}
                title={intl.formatMessage({id: 'MENU.ASSET.EQUIPMENT'})}
                to='/not-paid/asset'
                hasBullet={true}
              />
            )}
            {currentFeaturesObject && currentFeaturesObject.equipmentTransaction ? (
              <MenuItem
                title={intl.formatMessage({id: 'MENU.ASSET.TRANSACTION'})}
                to='/asset/transaction'
                hasBullet={true}
              />
            ) : (
              <MenuItem
                isDisabled={true}
                title={intl.formatMessage({id: 'MENU.ASSET.TRANSACTION'})}
                to='/not-paid/asset'
                hasBullet={true}
              />
            )}
          </>
        </MenuInnerWithSub>
      ) : (
        <MenuInnerWithSub
          isDisabled={true}
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.ASSET.DASHBOARD'})}
          to='/not-paid/asset'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/not-paid/asset'
              hasBullet={true}
            />
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'MENU.ASSET.MANUFACTURER'})}
              to='/not-paid/asset'
              hasBullet={true}
            />
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'MANUFACTURER.PRODUCT_PECIFICATIONS.TITLE'})}
              to='/not-paid/asset'
              hasBullet={true}
            />
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'MENU.ASSET.EQUIPMENT'})}
              to='/not-paid/asset'
              hasBullet={true}
            />
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'MENU.ASSET.TRANSACTION'})}
              to='/not-paid/asset'
              hasBullet={true}
            />
          </>
        </MenuInnerWithSub>
      )}

      {/* {currentModules?.includes('training') ? (
        <MenuInnerWithSub
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.TRAINING.DASHBOARD'})}
          to='/training/dashboard'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/training/dashboard'
              hasBullet={true}
            />
            <MenuItem
              title={intl.formatMessage({id: 'TRAINING.CERTIFICATE.TITLE'})}
              to='/training/certificate/list'
              hasBullet={true}
            />
            <MenuItem
              title={intl.formatMessage({id: 'TRAINING.INTEGRATION.TITLE'})}
              to='/training/employees-with-no-integration/list'
              hasBullet={true}
            />
          </>
        </MenuInnerWithSub>
      ) : (
        <MenuInnerWithSub
          hasArrow={true}
          isDisabled={true}
          title={intl.formatMessage({id: 'MENU.TRAINING.DASHBOARD'})}
          to='/not-paid/training'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/not-paid/training'
              hasBullet={true}
            />
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'TRAINING.CERTIFICATE.TITLE'})}
              to='/not-paid/training'
              hasBullet={true}
            />
            <MenuItem
              isDisabled={true}
              title={intl.formatMessage({id: 'TRAINING.INTEGRATION.TITLE'})}
              to='/not-paid/training'
              hasBullet={true}
            />
          </>
        </MenuInnerWithSub>
      )} */}

      {/* {currentModules?.includes('medical') ? (
        <MenuInnerWithSub
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.MEDICAL.DASHBOARD'})}
          to='/medical/dashboard'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/medical/dashboard'
            />
            <MenuItem
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.MEDICAL.MEDICAL_EVALUATION'})}
              to='/medical/medical-evaluations/list'
            />
          </>
        </MenuInnerWithSub>
      ) : (
        <MenuInnerWithSub
          hasArrow={true}
          isDisabled={true}
          title={intl.formatMessage({id: 'MENU.MEDICAL.DASHBOARD'})}
          to='/not-paid/medical'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              isDisabled={true}
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/not-paid/medical'
            />
            <MenuItem
              isDisabled={true}
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.MEDICAL.MEDICAL_EVALUATION'})}
              to='/not-paid/medical'
            />
          </>
        </MenuInnerWithSub>
      )} */}

      {/* {currentModules?.includes('risk') ? (
        <MenuInnerWithSub
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.RISK.DASHBOARD'})}
          to='/risk/dashboard'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/risk/dashboard'
            />
            {currentFeaturesObject && currentFeaturesObject.hazardManagement ? (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.HAZARD_MANAGEMENT'})}
                to='/risk/hazard-management/list'
              />
            ) : (
              <MenuItem
                isDisabled={true}
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.HAZARD_MANAGEMENT'})}
                to='/not-paid/risk'
              />
            )}
            {currentFeaturesObject && currentFeaturesObject.riskAnalysis ? (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.RISK_ANALYSIS'})}
                to='/risk/risk-analysis/list'
              />
            ) : (
              <MenuItem
                isDisabled={true}
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.RISK_ANALYSIS'})}
                to='/not-paid/risk'
              />
            )}
          </>
        </MenuInnerWithSub>
      ) : (
        <MenuInnerWithSub
          hasArrow={true}
          isDisabled={true}
          title={intl.formatMessage({id: 'MENU.RISK.DASHBOARD'})}
          to='/not-paid/risk'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              isDisabled={true}
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/not-paid/risk'
            />
            {currentModules?.includes('risk') &&
            currentFeaturesObject &&
            currentFeaturesObject.hazardManagement ? (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.HAZARD_MANAGEMENT'})}
                to='/risk/hazard-management/list'
              />
            ) : (
              <MenuItem
                isDisabled={true}
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.HAZARD_MANAGEMENT'})}
                to='/not-paid/risk'
              />
            )}
            {currentModules?.includes('risk') &&
            currentFeaturesObject &&
            currentFeaturesObject.riskAnalysis ? (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.RISK_ANALYSIS'})}
                to='/risk/risk-analysis/list'
              />
            ) : (
              <MenuItem
                isDisabled={true}
                hasBullet={true}
                title={intl.formatMessage({id: 'RISK.HEADER_TITLE.RISK_ANALYSIS'})}
                to='/not-paid/risk'
              />
            )}
          </>
        </MenuInnerWithSub>
      )} */}

      {/* {currentModules?.includes('access') ? (
        <MenuInnerWithSub
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.ACCESS.DASHBOARD'})}
          to='/access/dashboard'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/access/dashboard'
            />
            {currentFeaturesObject && currentFeaturesObject.workflow && (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'ACCESS.WORKFLOW'})}
                to='/access/workflow'
              />
            )}
            {currentFeaturesObject && currentFeaturesObject.workAuthorization && (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION'})}
                to='/access/work-authorization'
              />
            )}
          </>
        </MenuInnerWithSub>
      ) : (
        <MenuInnerWithSub
          isDisabled={true}
          hasArrow={true}
          title={intl.formatMessage({id: 'MENU.ACCESS.DASHBOARD'})}
          to='/not-paid/access'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              hasBullet={true}
              isDisabled={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/not-paid/access'
            />
            {currentModules?.includes('access') &&
            currentFeaturesObject &&
            currentFeaturesObject.workflow ? (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'ACCESS.WORKFLOW'})}
                to='/access/workflow'
              />
            ) : (
              <MenuItem
                isDisabled={true}
                hasBullet={true}
                title={intl.formatMessage({id: 'ACCESS.WORKFLOW'})}
                to='/not-paid/access'
              />
            )}
            {currentModules?.includes('access') &&
            currentFeaturesObject &&
            currentFeaturesObject.workAuthorization ? (
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION'})}
                to='/access/work-authorization'
              />
            ) : (
              <MenuItem
                isDisabled={true}
                hasBullet={true}
                title={intl.formatMessage({id: 'ACCESS.WORK_AUTHORIZATION'})}
                to='/not-paid/access'
              />
            )}
          </>
        </MenuInnerWithSub>
      )} */}

      {/* {currentModules?.includes('audit') ? (
        <>
          <MenuInnerWithSub
            hasArrow={true}
            title={intl.formatMessage({id: 'MENU.AUDIT.DASHBOARD'})}
            to='/audit/dashboard'
            menuPlacement='bottom-start'
            menuTrigger={"{default:'click', lg: 'hover'}"}
          >
            <>
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                to='/audit/dashboard'
              />
              <MenuItem
                hasBullet={true}
                title={intl.formatMessage({id: 'AUDIT.LATEST_OCURRANCES'})}
                to='/audit/occurrences-list'
              />
            </>
          </MenuInnerWithSub>
        </>
      ) : (
        <MenuInnerWithSub
          hasArrow={true}
          isDisabled={true}
          title={intl.formatMessage({id: 'MENU.AUDIT.DASHBOARD'})}
          to='/not-paid/audit'
          menuPlacement='bottom-start'
          menuTrigger={"{default:'click', lg: 'hover'}"}
        >
          <>
            <MenuItem
              isDisabled={true}
              hasBullet={true}
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              to='/not-paid/audit'
            />
            <MenuItem
              isDisabled={true}
              hasBullet={true}
              title={intl.formatMessage({id: 'AUDIT.LATEST_OCURRANCES'})}
              to='/not-paid/audit'
            />
          </>
        </MenuInnerWithSub>
      )} */}
    </>
  )
}
